import React from 'react';
import { FaTwitter, FaEnvelope } from 'react-icons/fa';

const SocialIcons: React.FC = () => {
  return (
    <div className="social-icons">
      <a href="https://x.com/block_atl" target="_blank" rel="noopener noreferrer">
        <FaTwitter size={45} />
      </a>  
      <a href="mailto:contact@blockatl.com">
        <FaEnvelope size={45} />
      </a>  
    </div>
  );
};

export default SocialIcons;
